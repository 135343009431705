import "vidstack/styles/base.css";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify-icon/react";

function MobileVideoPlayer({ url, cover, visibility, onEnded, setPlayingSlide, slideNumber, isMuted, setIsMuted }) {
    const player = useRef(null);
    const slider = useRef(0);
    const [status, setStatus] = useState(0);


    const handleTimeUpdate = () => {
        if (player.current) slider.current = player.current.currentTime;
    };

    const handleVideoClick = () => {
        if (player.current.paused === false) player.current.pause();
        else {
            setIsMuted(true);
            setPlayingSlide(slideNumber);
            player.current.play().catch((error) => {
                console.error("Autoplay error:", error);
                // Handle the autoplay error here, if needed
            });
            setStatus(1);
        }
    };

    useEffect(() => {
        if (visibility === false) player.current.pause()
    }, [visibility])
    return (
        <div className="relative w-full h-full flex justify-center items-center overflow-hidden">
            <video
                autoPlay
                onClick={handleVideoClick}
                onCanPlay={() => {
                    setIsMuted(true);
                    setPlayingSlide(slideNumber);
                    player.current.play().then(() => {
                        setStatus(1);
                    }).catch((error) => {
                        console.error("Autoplay error:", error);
                        // Handle the autoplay error here, if needed
                    });
                }}
                onLoadStart={() => {
                    setStatus(0);
                }}
                onPlay={() => {
                    setStatus(1);
                }}
                onPause={() => {
                    setStatus(2);
                }}
                onPlaying={() => {
                    if (status === 0) setStatus(1);
                }}
                onWaiting={() => {
                    setStatus(0);
                }}
                onEnded={() => {
                    setStatus(2);
                    onEnded();
                }}
                onTimeUpdate={handleTimeUpdate}
                ref={player}
                title="Agent 327: Operation Barbershop"
                src={url}
                poster={cover}
                muted={isMuted}
                className="w-full h-full overflow-hidden"
                playsInline
            ></video>
            <div className="absolute z-40">
                {
                    status === 0 && (
                        <Icon
                            onClick={() => {
                                if (player.current.paused === false) player.current.pause()
                                else player.current.play()
                            }}
                            icon="svg-spinners:270-ring"
                            className="text-3xl text-white"
                        ></Icon>
                    )
                }
                {
                    status === 2 && (
                        <Icon
                            onClick={() => {
                                if (player.current.paused === false) player.current.pause()
                                else player.current.play()
                            }}
                            icon="ic:baseline-play-arrow"
                            className="text-3xl text-white"
                        ></Icon>
                    )
                }
            </div>

        </div>

    )
}

export default MobileVideoPlayer;