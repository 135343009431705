import { Icon } from '@iconify-icon/react';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
// import VideoPlayerMobile from '../player/VideoPlayerMobile';
import MobileVideoPlayer from '../player/MobileVideoPlayer';
// import blurred from '../../../public/images/blurred.png'
import { Mousewheel } from 'swiper/modules';

function VideoPlayerMobileModel({ visibility, event, index }) {
    const [newId, setNewId] = useState(null)
    const [videoId, setVideoId] = useState(0)
    let id = index;
    const Data = useSelector((data) => data.slider)
    return (
        <div
            style={{ background: `url(${Data[newId === null ? id : newId]?.product?.videos[videoId === null ? 0 : videoId].cover})` }}
            className={`fixed top-0 left-0 z-50 w-screen max-h-screen ${visibility ? 'flex' : 'hidden'} justify-center items-center !bg-cover !bg-center !bg-no-repeat`}
        >
            <SMScreen event={event} visibility={visibility} Data={Data} id={newId === null ? id : newId} selectId={setNewId} videoId={videoId} selectVideoId={setVideoId} />
        </div>
    );
};

export default VideoPlayerMobileModel;

function SMScreen({ event, visibility, Data, id, selectId, videoId, selectVideoId }) {
    return (
        <div className='relative flex md:hidden w-screen h-[100dvh]'>
            <VideoSliderMobile
                AllData={Data}
                Data={Data[id]?.product?.videos}
                productData={Data[id]?.product}
                id={id} videoId={videoId} selectVideoId={selectVideoId} selectId={selectId}
                visibility={visibility}
                event={event}
            />
        </div>
    );
};

function VideoSliderMobile({ AllData, Data, productData, id, videoId, selectId, selectVideoId, visibility, event }) {
    const swiperRef = useRef(null);
    const secondSwiperRef = useRef([]);
    const [playingSlide, setPlayingSlide] = useState(videoId || 0);
    const [commentVisibility, setCommentVisibility] = useState(false);
    const [childSwiping, setChildSwiping] = useState(false);
    const [activeTab, setActiveTab] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMuted, setIsMuted] = useState(true);

    const handlePlayClick = (index) => {
        if (videoId === index) setPlayingSlide(index);
    };

    const handleVideoEnded = (index, condition) => {
        if (swiperRef.current) {
            if (!condition) {
                swiperRef.current.slideTo(index + 1)
                setPlayingSlide(index + 1);
            } else {
                if ((id) === (AllData.length - 1)) {
                    selectId(0)
                    selectVideoId(0)
                }else{
                    selectId(id + 1)
                    selectVideoId(0)
                }
            }
        }
    };

    useEffect(() => {
        async function Refresh() {
            if (swiperRef.current) {
                if (swiperRef.current.activeIndex !== videoId) {
                    await swiperRef.current.slideTo(videoId);
                }
                Data && Data?.forEach(async (d, i) => {
                    if (secondSwiperRef.current[i] !== null) await secondSwiperRef.current[i].current.swiper.slideTo(0)
                })

            }
        }
        Refresh()
    }, [videoId, Data]);
    return (
        <div className='relative w-full h-full'>
            <div className={`absolute top-0 z-50 w-full ${activeIndex === 0 ? 'flex' : 'hidden'} flex-col gap-2 p-4`}>
                <div className='w-full flex justify-between items-center'>
                    <div className='flex items-center gap-2 text-2xl sm:text-3xl font-[500] uppercase text-white font-[Manscaped]'>
                        <span>
                            {`LAWN MOWER`}
                        </span>
                        <span className='text-sand-1'>
                            {`4.0`}
                        </span>
                    </div>
                    <Icon onClick={() => event(!visibility)} className='text-xl sm:text-2xl text-sand-1 cursor-pointer' icon={'maki:cross-11'} />
                </div>
                <div className='w-full flex justify-between items-center'>
                    <button className='w-max rounded-full text-base sm:text-lg text-white bg-sand-1 py-2 px-4 font-[GraphikSemibold]'>
                        {`Shop Now`}
                    </button>
                    {
                        isMuted
                            ?
                            <div
                                onClick={() => {
                                    setIsMuted(!isMuted)
                                }}
                                className="cursor-pointer"
                            >
                                <Icon
                                    icon="octicon:mute-16"
                                    className="text-xl text-sand-1"
                                ></Icon>
                            </div>
                            :
                            <div
                                onClick={() => {
                                    setIsMuted(!isMuted)
                                }}
                                className="cursor-pointer"
                            >
                                <Icon
                                    icon="octicon:unmute-16"
                                    className="text-xl text-sand-1"
                                ></Icon>
                            </div>
                    }

                </div>
            </div>
            <div className={`absolute ${commentVisibility || activeIndex !== 0 ? 'hidden' : 'flex'}  w-full bottom-[0%] flex-col z-[60]`}>
                <div className={`w-full flex justify-between p-3 duration-500`}>
                    <div className="w-7/12 flex flex-col justify-end items-start gap-1 banner-text-1 text-3xl xs:text-4xl sm:text-5xl select-none font-[FreightBigProBlack-Regular]">
                        <span>
                            {`My Balls`}
                        </span>
                        <span>
                            {`Was Hot.`}
                        </span>
                    </div>
                    <div className="w-1/12 flex flex-col justify-center items-center gap-5">
                        <Icon className={`text-sand-1 text-3xl cursor-pointer`} icon={'material-symbols:favorite-rounded'} />
                        <Icon onClick={() => { setCommentVisibility(true) }} className={`text-sand-1 text-3xl cursor-pointer`} icon={'fa6-solid:comment-dots'} />
                        <Icon className={`text-sand-1 text-3xl cursor-pointer`} icon={'ph:share-fat-fill'} />
                    </div>
                </div>
                <hr className='w-full border-t border-b-0 border-light-grey' />
                <div className="w-full flex flex-col gap-2 p-3 py-2 cursor-pointer backdrop-blur-8 bg-[rgba(255,255,255,0.3)]">
                    <div className="w-full flex justify-start items-center gap-[2px]  text-white text-sm sm:text-base font-[GraphikRegular]">
                        <span>
                            {`Watching`}
                        </span>
                        <span className="font-bold">
                            {`#Lawnmower 4.0`}
                        </span>
                        <span>
                            {`Videos`}
                        </span>
                    </div>
                    <div className={`w-full h-max flex items-center gap-3 overflow-x-auto duration-500 pb-4`}>
                        {
                            AllData && AllData?.map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`min-w-[80px] sm:min-w-[100px] h-[80px] sm:h-[100px] rounded-xl overflow-hidden bg-white ${id === index ? 'border-[3px]' : ''} hover:border-[3px] border-sand-1`}
                                    >
                                        <img
                                            onClick={() => {
                                                selectId(index)
                                                selectVideoId(0)
                                            }}
                                            className='cursor-pointer' src={data?.product?.image} alt="thumb"
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

            </div>
            <Swiper
                ref={swiperRef}
                initialSlide={videoId || 0}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                onSlideChange={(swiper) => {
                    setPlayingSlide(swiper.realIndex);
                    selectVideoId(swiper.realIndex);
                    setCommentVisibility(false)
                }}
                allowSlideNext={!childSwiping}
                allowSlidePrev={!childSwiping}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                spaceBetween={0}
                speed={800}
                passiveListeners={false}
                pagination={{
                    clickable: true,
                }}
                direction='vertical'
                className="w-full h-full bg-black overflow-hidden"
                modules={[Mousewheel]}
                mousewheel={{
                    sensitivity: 50
                }}
            >
                {
                    Data && Data?.map((data, index) => {
                        if (!secondSwiperRef.current[index]) {
                            secondSwiperRef.current[index] = React.createRef();
                        }
                        return (
                            <SwiperSlide key={index} className='w-full h-full'>
                                <Swiper
                                    ref={secondSwiperRef.current[index]}
                                    initialSlide={0}
                                    effect={"coverflow"}
                                    onSlideChange={(slide) => {
                                        if (slide.activeIndex === 1) setChildSwiping(true)
                                        else setChildSwiping(false)
                                        // setPlayingSlide(null)
                                        setIsMuted(true)
                                        setActiveIndex(slide.activeIndex);
                                    }}
                                    grabCursor={true}
                                    centeredSlides={true}
                                    spaceBetween={0}
                                    speed={800}
                                    direction='horizontal'
                                    className="w-full h-full"
                                >
                                    <SwiperSlide className='relative w-full h-full overflow-hidden'>

                                        <CommentSection Data={Data[videoId]?.comments} commentVisibility={commentVisibility} setCommentVisibility={setCommentVisibility} />
                                        {
                                            playingSlide === index
                                                ?
                                                <>
                                                    <MobileVideoPlayer
                                                        url={data?.url} cover={data?.cover} visibility={visibility} onEnded={() => handleVideoEnded(index, index === Data.length - 1)}
                                                        isMuted={isMuted} setIsMuted={setIsMuted}
                                                        setPlayingSlide={setPlayingSlide} slideNumber={index}
                                                    />

                                                </>
                                                :
                                                <div className="relative w-full h-full flex justify-center items-center">
                                                    <img
                                                        src={data?.cover}
                                                        className="w-max h-max"
                                                        alt="cover"
                                                    />
                                                    <div
                                                        onClick={() => handlePlayClick(index)}
                                                        className={`absolute w-full h-full flex items-center justify-center z-50 ${videoId === index ? "cursor-pointer" : ""}`}
                                                    >
                                                        {
                                                            (videoId === index) && <Icon className="text-3xl text-white" icon="ic:baseline-play-arrow" />
                                                        }
                                                    </div>
                                                </div>
                                        }
                                    </SwiperSlide>
                                    <SwiperSlide className='relative w-full h-full !overflow-y-auto hidescroll'>
                                        <div className=' top-0 w-full flex justify-between items-center p-4 text-light-grey-1 z-[999]'>
                                            <Icon
                                                onClick={() => {
                                                    if (secondSwiperRef.current[index]) secondSwiperRef.current[index].current.swiper.slideTo(0)
                                                }}
                                                className='cursor-pointer text-2xl sm:text-3xl' icon={'material-symbols:arrow-back-ios-rounded'}
                                            />
                                            <Icon className='cursor-pointer text-2xl sm:text-3xl' icon={'ic:outline-search'} />
                                        </div>
                                        <div

                                            style={{ background: 'url(/images/blurred.png)' }}
                                            className='w-full !bg-cover !bg-center !bg-no-repeat'
                                        >
                                            <div className='w-full flex flex-col justify-center items-center gap-6 py-20 '>
                                                <img
                                                    className='w-[180px] xs:w-[200px] sm:w-[220px] max-h-[180px] xs:max-h-[200px] sm:max-h-[220px] rounded-full bg-white'
                                                    src={productData?.image} alt="product"
                                                />
                                                <div className='w-full flex flex-col justify-center items-center gap-5 text-white font-[Manscaped]'>
                                                    <div className='w-full flex flex-col justify-center items-center gap-2'>
                                                        <span className='text-3xl xs:text-4xl sm:text-5xl uppercase'>
                                                            {`The`}
                                                        </span>
                                                        <span className='text-5xl xs:text-6xl sm:text-7xl leading-7 uppercase'>
                                                            {`BEARD HEDGER`}
                                                        </span>
                                                    </div>
                                                    <button className='w-max rounded-full text-sm xs:text-base bg-sand-1 py-3 px-5 font-[GraphikSemibold]'>
                                                        {`Shop Now`}
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='w-full flex items-end text-xs lg:text-sm text-grey-2 font-[500] font-[GraphikMedium] uppercase'>
                                            <div onClick={() => setActiveTab(0)} className={`w-1/2 text-center border-b-2 pb-2 ${activeTab === 0 ? 'border-sand-1 text-white' : 'border-grey-2'}  cursor-pointer`}>
                                                {`MORE LIKE THIS`}
                                            </div>
                                            <div onClick={() => setActiveTab(1)} className={`w-1/2 text-center border-b-2 pb-2 ${activeTab === 1 ? 'border-sand-1 text-white' : 'border-grey-2'}  cursor-pointer`}>
                                                {`LIKED VIDEOS`}
                                            </div>
                                        </div>
                                        <MobileTabOne activeTab={activeTab} Data={Data} selectVideoId={selectVideoId} setPlayingSlide={setPlayingSlide} setChildSwiping={setChildSwiping} />
                                    </SwiperSlide>
                                </Swiper>
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div >
    );
};

function MobileTabOne({ activeTab, Data, selectVideoId, setPlayingSlide, setChildSwiping }) {
    return (
        <div className={`w-full ${activeTab === 0 ? 'flex' : 'hidden'} flex-wrap items-center gap-x-2 gap-y-2 pl-2 pt-2`}>
            {
                Data && Data?.map((data, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                selectVideoId(index)
                                setPlayingSlide(index)
                                setChildSwiping(false)
                            }}
                            className='w-[31.3%] xs:w-[32%] h-[215px] xs:h-[300px] sm:h-[350px] cursor-pointer'
                        >
                            <img className='w-full h-full' src={data?.cover} alt="cover" />
                        </div>
                    );
                })
            }
        </div>
    );
};

function CommentSection({ Data, commentVisibility, setCommentVisibility }) {
    return (
        <div className={`${commentVisibility ? 'translate-y-0' : 'translate-y-full'} duration-500 absolute bottom-0 z-[99] bg-off-white-1 rounded-t-lg w-full h-[60vh] font-[GraphikMedium]`}>
            <div className='relative w-full h-full flex flex-col rounded-t-lg'>
                <div className='w-full h-[10%] flex justify-between items-center px-4 text-grey-2'>
                    <div className='text-base sm:text-lg font-[500]'>
                        {`COMMENTS`}
                    </div>
                    <Icon onClick={() => { setCommentVisibility(false) }} className='text-2xl sm:text-3xl cursor-pointer' icon={'maki:cross-11'} />
                </div>
                <div className='w-full h-[calc(90%-55px)] flex flex-col gap-4 overflow-y-auto py-2'>
                    {
                        Data && Data?.map((data, index) => {
                            return (
                                <div key={index} className='w-full flex justify-between items-start'>
                                    <div className='w-2/12 flex justify-center items-center'>
                                        <div className='w-10 sm:w-12 h-10 sm:h-12 flex justify-center items-center rounded-full bg-light-grey-2'>
                                            <span className='text-xs sm:text-sm text-white font-[500] font-[GraphikRegular]'>
                                                {`MG`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='w-8/12 flex flex-col items-start gap-1 text-grey-4'>
                                        <span className='text-sm sm:text-base font-[500] '>
                                            {data?.username}
                                        </span>
                                        <span className='text-xs sm:text-sm text-dark-2 font-[GraphikRegular]'>
                                            {data?.comment}
                                        </span>
                                        <div className='flex items-center gap-4 text-grey-4'>
                                            <span className='text-xs sm:text-sm'>
                                                {`View Replies (5)`}
                                            </span>
                                            <Icon className='text-lg sm:text-xl cursor-pointer' icon={'ic:twotone-keyboard-arrow-down'} />
                                        </div>
                                    </div>
                                    <div className='w-2/12 h-full flex flex-col justify-center items-center text-grey-3'>
                                        <Icon className='text-xl sm:text-2xl cursor-pointer' icon={'material-symbols:favorite-outline-rounded'} />
                                        <span className='text-sm sm:text-base font-[500] font-[GraphikRegular]'>
                                            {`05`}
                                        </span>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className='absolute bottom-0 w-full h-[55px] font-[GraphikRegular]'>
                    <div className='relative w-full h-full z-[999]'>
                        <input className='w-full h-full text-dark-2 text-sm lg:text-base outline-none border-none px-6 pr-36 bg-white' type="text" placeholder='Add comment...' />
                        <img className='absolute top-[32%] right-[80px] !w-5 !h-5 cursor-pointer' src="images/atsigndark.svg" alt="@" />
                        <img className='absolute top-[32%] right-[35px] !w-5 !h-5 cursor-pointer' src="images/emojidark.svg" alt="@" />
                    </div>
                </div>
            </div>
        </div>
    );
};