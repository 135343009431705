import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination, Mousewheel } from "swiper/modules";
import { useSelector } from "react-redux";
import VideoPlayerMobileModel from "../models/VideoPlayerMobileModel";
import VideoPlayerDesktopModel from "../models/VideoPlayerDesktopModel";
import { MdiVolumeVariantOff } from "../common/MdiVolumeVariantOff";
import { MdiVolume } from "../common/MdiVolume";


export default function VideoSlider({ visibility, setVisibility }) {
    const swiperRef = useRef(null);
    const slides = useSelector((data) => data.slider)
    const [activeSlide, setActiveSlide] = useState(0);
    const [isMuted, setIsMuted] = useState(true)
    const videoRefs = useRef([]);

    const handleSlideChange = (swiper) => {
        const newIndex = swiper.realIndex;
        console.log(newIndex)

        if (videoRefs.current[newIndex].current.muted === true) {
            setIsMuted(true)
        } else {
            setIsMuted(false)
        }

        videoRefs.current.forEach((videoRef, idx) => {
            if (videoRef && videoRef.current) {
                if (idx === newIndex) {
                    videoRef.current.play().then(() => {
                        // Playback started successfully.
                    }).catch(error => {
                        // Playback failed.
                        console.log('Playback failure:', error);
                        // Handle accordingly, maybe show a play button?
                    });
                } else {
                    videoRef.current.pause();
                }
            }
        });

        setActiveSlide(newIndex);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        if (videoRefs.current[1] && videoRefs.current[1].current) {
            videoRefs.current[1].current.play().then(() => {
                // Playback started successfully.
                console.log(videoRefs.current[1])
            }).catch(error => {
                // Playback failed.
                console.log('Playback failure:', error);
                // Handle accordingly, maybe show a play button?
            });;
        }
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);
    return (
        <>
            <Swiper
                ref={swiperRef}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                initialSlide={1}
                onSlideChange={handleSlideChange}
                grabCursor={true}
                centeredSlides={true}
                spaceBetween={20}
                speed={800}
                breakpoints={{
                    380: {
                        slidesPerView: 1.6,
                    },
                    600:{
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 3.2,
                    },
                    1440: {
                        slidesPerView: 4,
                    },
                }}
                pagination={{
                    clickable: true,
                }}
                mousewheel={true}
                modules={[Pagination, Mousewheel]}
                className="w-[90%] h-max lg:h-full !py-10 lg:!py-0"
            >
                {
                    slides.map((slide, index) => {
                        const condition = index % 2 === 0;

                        if (!videoRefs.current[index]) {
                            videoRefs.current[index] = React.createRef();
                        }
                        return (
                            <SwiperSlide
                                onClick={() => {
                                    if (swiperRef.current.realIndex === index) {
                                        videoRefs.current[index].current.pause()
                                        setVisibility(true)
                                    }
                                }}
                                key={index} className={`group relative z-0 !h-max ${condition ? "mt-12 lg:mt-20" : ""} overflow-hidden `}
                            >

                                <div className="relative z-10 overflow-hidden ">
                                    <video
                                        ref={videoRefs.current[index]}
                                        className={`w-full h-full md:h-[85%] relative top-0 right-0 -z-[10]`}
                                        src={slide?.product?.videos[0]?.url}
                                        loop
                                        playsInline
                                        muted={true}
                                    />
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (videoRefs?.current[index].current !== null) videoRefs.current[index].current.muted = true
                                            setIsMuted(true)
                                        }}
                                        style={{ zIndex: 1000 }}
                                        className={` ${activeSlide === index && isMuted === false ? 'block' : 'hidden'} volumeON muteIcon absolute right-[25px] bottom-[15px] text-3xl text-white cursor-pointer`}
                                    >
                                        <MdiVolume
                                            style={{ zIndex: 1000 }}
                                            className={` ${activeSlide === index && isMuted === false ? 'block' : 'hidden'} volumeON muteIcon text-3xl text-white cursor-pointer`}
                                        />
                                    </div>
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (videoRefs?.current[index].current !== null) videoRefs.current[index].current.muted = false
                                            setIsMuted(false)
                                        }}
                                        style={{ zIndex: 1000 }}
                                        className={` ${activeSlide === index && isMuted === true ? 'block' : 'hidden'} volumeOFF muteIcon absolute right-[25px] bottom-[15px] text-3xl text-white cursor-pointer`}
                                    >
                                        <MdiVolumeVariantOff
                                            style={{ zIndex: 1000 }}
                                            className={` ${activeSlide === index && isMuted === true ? 'block' : 'hidden'} volumeOFF muteIcon text-3xl text-white cursor-pointer`}
                                        />
                                    </div>

                                </div>
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
            {
                windowWidth <= 767
                    ?
                    <VideoPlayerMobileModel visibility={visibility} event={setVisibility} index={activeSlide} />
                    :
                    <VideoPlayerDesktopModel visibility={visibility} event={setVisibility} index={activeSlide} />
            }
        </>
    );
};