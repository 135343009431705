const initialState = {
    slider: [
        {
            index: 0,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
            product: {
                name: "Lawnmower 4.0",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/lm_4_base_img_01_c8b13de1-d8ec-44b3-b2b3-73aac64449fd_750x750_crop_center.jpg?v=1686855226",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 2,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            },
        },
        {
            index: 1,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/ww2-carousel-1_2_190d140d-71dc-4a35-91a0-c8681336d6a0_750x750_crop_center.png?v=1686855234",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 4,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 2,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/2-in-one-shamppoo-and-conditioner_bodywash_750x750_crop_center.jpg?v=1686855248",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 1,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 3,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
            product: {
                name: "product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/CROP_REVIVER_F01_f248233c-8fb5-4e76-baa2-fd33e672977e_750x750_crop_center.png?v=1686855254",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 3,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 4,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/CROP_PRESERVER_F01_a398b34c-8bab-4bb9-84ac-b8c337492462_750x750_crop_center.png?v=1686855261",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 4,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 5,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/6/img6.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/6/vid6.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/DEODORANT_7532aa5e-c12a-4df4-8eee-906edeb47fb1_750x750_crop_center.png?v=1686855267",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 1,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 6,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/7/img7.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/7/vid7.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/MANSCAPEDHANDYMANCAROUSEL12400px_750x750_crop_center.png?v=1687458442",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 1,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 7,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/8/img8.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/8/vid8.mp4",
            product: {
                name: "Product Name",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/ultra_smooth_3_1000x1000_crop_center.jpg?v=1686855402",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 3,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        },
        {
            index: 8,
            cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/9/img9.png",
            url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/9/vid9.mp4",
            product: {
                name: "Menscaped",
                image: "https://cdn.shopify.com/s/files/1/1383/0317/files/ultra_smooth_4_1000x1000_crop_center.jpg?v=1686855409",
                price: "$99.99",
                discountPrice: "$49.99",
                link: "https://www.menscaped.com",
                time: 2,
                videos: [
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/vid1.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/1/img1.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/vid2.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/2/img2.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/vid3.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/3/img3.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'John Daly',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/vid4.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/4/img4.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    },
                    {
                        title: 'My Balls Was Hot.',
                        url: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/vid5.mp4",
                        cover: "https://manescaped-video.s3.ap-south-1.amazonaws.com/Assets/5/img5.png",
                        comments: [
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            },
                            {
                                username: 'User Name',
                                comment: 'This is a wonderful comment',
                                likes: 5
                            }
                        ]
                    }
                ]
            }
        }
    ],
    product: [
        {
            "name": "The Lawn Mower® 4.0",
            "imageSrc": "images/banner.png"
        },
        {
            "name": "Shampoo + Conditioner",
            "imageSrc": "/img/2-in-1-shampoo-conditioner.png"
        },
        {
            "name": "The Lawn Mower® 3.0",
            "imageSrc": "/img/lawn-mower-manscaping-3.png"
        },
        {
            "name": "Weed Whacker® 2.0",
            "imageSrc": "/img/weed-whacker-2.png"
        },
        {
            "name": "The Beard Hedger™",
            "imageSrc": "/img/the-beard-hedger.png"
        },
        {
            "name": "Crop Preserver®",
            "imageSrc": "/img/crop-preserver-manscaping.png"
        },
        {
            "name": "Beard Shampoo",
            "imageSrc": "/img/beard-shampoo.png"
        },
        {
            "name": "Beard Conditioner",
            "imageSrc": "/img/beard-conditioner.png"
        },
        {
            "name": "Boxers 2.0",
            "imageSrc": "/img/boxers-2.png"
        },
        {
            "name": "Body Wash",
            "imageSrc": "/img/body-wash.png"
        },
        {
            "name": "Crop Mop®",
            "imageSrc": "/img/crop-mop-wipes.png"
        }
    ],
    productImages: [
        {
            id: 1,
            image: 'images/product/img-1.png'
        },
        {
            id: 2,
            image: 'images/product/img-2.png'
        },
        {
            id: 3,
            image: 'images/product/img-3.jpg'
        },
        {
            id: 4,
            image: 'images/product/img-4.jpg'
        },
        {
            id: 5,
            image: 'images/product/img-5.jpg'
        }
    ]
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case "ADD_COMMENT": {
            return {
                ...state,
                slider: state.slider.map((sliderItem) => {
                    if (sliderItem.index === action.productIndex && sliderItem.product.videos[action.videoIndex]) {
                        sliderItem.product.videos[action.videoIndex].comments.push(action.comment);
                    }
                    return sliderItem;
                }),
            };
        }
        default: {
            return state;
        }
    }
}