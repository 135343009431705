import { Icon } from '@iconify-icon/react';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import VideoPlayerDesktop from '../player/VideoPlayerDesktop';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from 'swiper/modules';
import "swiper/css";

function VideoPlayerDesktopModel({ visibility, event, index }) {
    const [newId, setNewId] = useState(null)
    const [videoId, setVideoId] = useState(0)
    let id = index;
    const Data = useSelector((data) => data.slider)
    return (
        <div
            style={{ background: `url(${Data[newId === null ? id : newId]?.product?.videos[videoId === null ? 0 : videoId].cover})` }}
            className={`fixed top-0 left-0 z-50 w-screen h-screen ${visibility ? 'flex' : 'hidden'} justify-center items-center !bg-cover !bg-center !bg-no-repeat font-[GraphikMedium]`}
        >
            <div className='w-full h-full backdrop-blur-13 bg-bg-blur'>
                <MDScreen event={event} visibility={visibility} Data={Data} id={newId === null ? id : newId} selectId={setNewId} videoId={videoId} selectVideoId={setVideoId} />
            </div>
        </div>
    );
};

export default VideoPlayerDesktopModel;

function MDScreen({ event, visibility, Data, id, selectId, videoId, selectVideoId }) {
    const [optionState, setOptionState] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    return (
        <div className='hidden md:flex w-full h-full'>
            <div className='relative w-7/12 mid-xl:w-8/12 h-full flex justify-center lg:justify-end mid-xl:justify-center items-center pr-0 lg:pr-16 mid-xl:pr-0'>
                <img className='absolute left-[20px] top-[20px] hidden lg:block w-max h-max' src="images/logo.png" alt="logo" />
                <Icon className='absolute top-[20px] right-[20px] hidden lg:block text-grey-3 text-3xl lg:text-4xl cursor-pointer' icon={'ph:magnifying-glass-light'} />
                {
                    optionState === false
                        ?
                        <Icon
                            onClick={() => setOptionState(true)}
                            className='absolute bottom-[20px] right-[20px] block lg:hidden text-3xl text-white cursor-pointer'
                            icon={'material-symbols:expand-circle-up-outline-rounded'}
                        />
                        :
                        <Icon
                            onClick={() => setOptionState(false)}
                            className='absolute bottom-[20px] right-[20px] block lg:hidden text-3xl text-white cursor-pointer'
                            icon={'material-symbols:expand-circle-down-outline-rounded'}
                        />
                }
                <div
                    className={`absolute top-[90%] lg:top-[20%] lg:left-[10px] !z-[99] ${optionState ? 'opacity-100' : 'opacity-20 translate-y-[80px]'}
                    w-max h-max flex flex-row lg:flex-col gap-1 lg:opacity-20 hover:lg:opacity-100 duration-500 lg:duration-200 select-none`}
                >
                    {
                        Data && Data?.map((data, index) => {
                            return (
                                <div key={index} className={`w-max h-[80px] xl:h-[100px] ${index <= 4 ? 'flex' : 'hidden'} items-center gap-2 font-[GraphikRegular]`}>
                                    <div className={`w-[80px] xl:w-[100px] h-full rounded-xl overflow-hidden ${id === index ? 'border-2' : ''} hover:border-2 border-sand-1 bg-white`}>
                                        <img
                                            onClick={() => {
                                                selectId(index)
                                                selectVideoId(0)
                                                setOptionState(false)       // Test Feature or Can Be Removed
                                            }}
                                            className='cursor-pointer' src={data?.product?.image} alt="thumb"
                                        />
                                    </div>
                                    <div className='hidden xl:flex flex-col items-start'>
                                        <span className={`${id === index ? 'block' : 'hidden'} text-sand-1 text-sm font-bold`}>
                                            {`WATCHING`}
                                        </span>
                                        <span className='text-sm text-white font-[600]'>
                                            {`#${data?.product?.name}`}
                                        </span>
                                        <div className='flex items-center gap-1 text-sm font-bold'>
                                            {
                                                data?.product?.time <= 2
                                                    ?
                                                    <span className=' text-sand-1'>
                                                        {`NEW`}
                                                    </span>
                                                    :
                                                    <></>
                                            }
                                            <span className='text-grey-3'>
                                                {`${data?.product?.time}h`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <VideoSlider Data={Data[id]?.product?.videos} videoId={videoId} selectVideoId={selectVideoId} visibility={visibility} />
            </div>
            <div className='w-5/12 mid-xl:w-4/12 h-full flex flex-col bg-bg-blur-1'>
                <div className='w-full h-[12vh] flex justify-between items-start p-5'>
                    <div className='flex items-center gap-3'>
                        <div className='flex items-center gap-2 text-xl lg:text-2xl xl:text-3xl font-[500] uppercase text-white font-[Manscaped]'>
                            <span>
                                {`LAWN MOWER`}
                            </span>
                            <span className='text-sand-1'>
                                {`4.0`}
                            </span>
                        </div>
                        <button className='w-max rounded-full text-xs lg:text-sm text-white bg-sand-1 py-1 px-3 font-[GraphikSemibold]'>
                            {`Shop Now`}
                        </button>
                    </div>
                    <Icon onClick={() => event(!visibility)} className='text-lg lg:text-xl xl:text-2xl text-sand-1 cursor-pointer' icon={'maki:cross-11'} />
                </div>
                <div className='w-full h-[4vh] flex items-end text-xs lg:text-sm text-grey-2 font-[500] uppercase'>
                    <div onClick={() => setActiveTab(0)} className={`w-1/3 text-center border-b-2 pb-1 ${activeTab === 0 ? 'border-sand-1 text-white' : 'border-grey-2'}  cursor-pointer`}>
                        {`THIS VIDEO`}
                    </div>
                    <div onClick={() => setActiveTab(1)} className={`w-1/3 text-center border-b-2 pb-1 ${activeTab === 1 ? 'border-sand-1 text-white' : 'border-grey-2'}  cursor-pointer`}>
                        {`MORE LIKE THIS`}
                    </div>
                    <div onClick={() => setActiveTab(2)} className={`w-1/3 text-center border-b-2 pb-1 ${activeTab === 2 ? 'border-sand-1 text-white' : 'border-grey-2'}  cursor-pointer`}>
                        {`LIKED VIDEOS`}
                    </div>
                </div>
                <TabOne activeTab={activeTab} Data={Data[id]?.product?.videos[videoId]?.comments} />
                <TabTwo activeTab={activeTab} Data={Data[id]?.product} selectVideoId={selectVideoId} />
                <div className={`${activeTab === 0 ? 'block' : 'hidden'} relative w-full h-[55px] font-[GraphikRegular]`}>
                    <input className='w-full h-full text-white text-sm lg:text-base outline-none border-none px-6 pr-36 bg-grey-1' type="text" placeholder='Add comment...' />
                    <img className='absolute top-[32%] right-[80px] !w-5 !h-5 cursor-pointer' src="images/atsign.svg" alt="@" />
                    <img className='absolute top-[32%] right-[35px] !w-5 !h-5 cursor-pointer' src="images/emoji.svg" alt="@" />
                </div>
            </div>
        </div>
    );
};

function TabOne({ activeTab, Data }) {
    return (
        <div className={`${activeTab === 0 ? 'flex' : 'hidden'} w-full h-[calc(84vh-55px)] flex-col !overflow-y-auto hidescroll`}>
            <div className='w-full flex flex-col justify-end items-start banner-text-1 text-3xl lg:text-5xl p-6 pt-20 font-[FreightBigProBlack-Regular]'>
                <span>
                    {`My Balls`}
                </span>
                <span>
                    {`Was Hot`}
                </span>
            </div>
            <hr className='w-full border-gray-600' />
            <div className='w-full flex justify-start items-center gap-8 p-4 px-6'>
                <div className='flex items-center gap-2'>
                    <Icon className='text-sand-1 text-2xl' icon={'material-symbols:favorite-rounded'} />
                    <span className='text-grey-2 text-sm'>
                        {'2.1K'}
                    </span>
                </div>
                <div className='flex items-center gap-2'>
                    <Icon className='text-sand-1 text-2xl' icon={'fa6-solid:comment-dots'} />
                    <span className='text-grey-2 text-sm'>
                        {'2.1K'}
                    </span>
                </div>
                <Icon className='text-sand-1 text-2xl' icon={'ph:share-fat-fill'} />
            </div>
            <hr className='w-full border-gray-600' />
            <div className='w-full flex flex-col gap-4 p-6 font-[GraphikMedium]'>
                <div className='text-sm lg:text-base text-grey-2 font-[500]'>
                    {`COMMENTS (${Data?.length})`}
                </div>
                {
                    Data && Data?.map((data, index) => {
                        return (
                            <div key={index} className='w-full flex justify-between items-start'>
                                <div className='w-2/12 flex justify-center items-center'>
                                    <div className='w-8 lg:w-10 h-8 lg:h-10 flex justify-center items-center rounded-full bg-sand-1'>
                                        <span className='text-xs lg:text-sm text-dark-1 font-[600] font-[GraphikRegular]'>
                                            {`MG`}
                                        </span>
                                    </div>
                                </div>
                                <div className='w-8/12 flex flex-col items-start gap-1 text-white'>
                                    <span className='text-sm lg:text-base font-[500]'>
                                        {data?.username}
                                    </span>
                                    <span className='text-xs lg:text-sm font-[GraphikRegular]'>
                                        {data?.comment}
                                    </span>
                                    <div className='flex items-center gap-4 text-grey-3'>
                                        <span className='text-xs lg:text-sm font-[GraphikRegular]'>
                                            {`View Replies (5)`}
                                        </span>
                                        <Icon className='text-lg lg:text-xl cursor-pointer' icon={'ic:twotone-keyboard-arrow-down'} />
                                    </div>
                                </div>
                                <div className='w-2/12 h-full flex flex-col justify-center items-center text-grey-3'>
                                    <Icon className='text-xl lg:text-2xl cursor-pointer' icon={'material-symbols:favorite-outline-rounded'} />
                                    <span className='text-sm lg:text-base font-[500] font-[GraphikRegular]'>
                                        {`05`}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

function TabTwo({ activeTab, Data, selectVideoId }) {
    return (
        <div className={`${activeTab === 1 ? 'block' : 'hidden'} w-full h-[84vh] !overflow-y-auto hidescroll`}>
            <div className='w-full flex flex-col justify-center items-center gap-6 py-20'>
                <img className='w-[180px] lg:w-[200px] xl:w-[220px] max-h-[180px] lg:max-h-[200px] xl:max-h-[220px] rounded-full bg-white' src={Data?.image} alt="product" />
                <div className='w-full flex flex-col justify-center items-center gap-1 text-white font-[Manscaped]'>
                    <span className='text-xl lg:text-2xl xl:text-3xl uppercase'>
                        {`The`}
                    </span>
                    <span className='text-3xl lg:text-4xl xl:text-5xl leading-7 uppercase'>
                        {`BEARD HEDGER`}
                    </span>
                    <button className='w-max rounded-full text-xs lg:text-sm bg-sand-1 py-1 px-3 font-[GraphikSemibold]'>
                        {`Shop Now`}
                    </button>
                </div>
            </div>
            <div className='w-full flex flex-wrap items-center gap-x-2 gap-y-2 pl-2'>
                {
                    Data && Data?.videos?.map((data, index) => {
                        return (
                            <div key={index} onClick={() => { selectVideoId(index) }} className='w-[48%] lg:w-[32%] h-[350px] cursor-pointer'>
                                <img className='w-full h-full' src={data?.cover} alt="cover" />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

function VideoSlider({ Data, videoId, selectVideoId, visibility }) {
    const swiperRef = useRef(null);
    const [playingSlide, setPlayingSlide] = useState(videoId || 0);
    const handlePlayClick = (index) => {
        if (videoId === index) setPlayingSlide(index);
    };

    const handleVideoEnded = () => {
        setPlayingSlide(null);
    };

    useEffect(() => {
        if (swiperRef.current) {
            if (swiperRef.current.activeIndex !== videoId) swiperRef.current.slideTo(videoId);
        }
    }, [videoId]);

    return (
        <div className={`w-[445px] lg:w-[470px] mid-xl:w-[480px] 2xl:w-[520px] md:h-[85%] xl:h-[88%] 2xl:h-[91%]`}>
            <Swiper
                ref={swiperRef}
                initialSlide={videoId || 0}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                onSlideChange={(swiper) => {
                    setPlayingSlide(swiper.realIndex);
                    selectVideoId(swiper.realIndex);
                }}
                grabCursor={true}
                centeredSlides={true}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                speed={800}
                mousewheel={true}
                modules={[Mousewheel]}
                direction='vertical'
                className="w-full h-full rounded-xl overflow-hidden bg-black"
            >
                {
                    Data && Data?.map((data, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {
                                    playingSlide === index
                                        ?
                                        <VideoPlayerDesktop url={data?.url} cover={data?.cover} visibility={visibility} onEnded={handleVideoEnded} setPlayingSlide={setPlayingSlide} slideNumber={index}/>
                                        :
                                        <div className="relative flex justify-center items-center">
                                            <img
                                                src={data?.cover}
                                                className="w-full h-full object-cover"
                                                alt="cover"
                                            />
                                            <div
                                                onClick={() => handlePlayClick(index)}
                                                className={`absolute w-full h-full flex items-center justify-center z-50 ${videoId === index ? "cursor-pointer" : ""}`}
                                            >
                                                {
                                                    (videoId === index)
                                                    &&
                                                    (
                                                        <Icon className="text-3xl text-white" icon="ic:baseline-play-arrow" />
                                                    )
                                                }
                                            </div>
                                        </div>
                                }
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div>
    );
};