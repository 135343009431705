import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { useSelector } from 'react-redux';
import VideoPlayerDesktopModel from '../components/models/VideoPlayerDesktopModel';
import VideoPlayerMobileModel from '../components/models/VideoPlayerMobileModel';
import { Icon } from '@iconify-icon/react';

function ProductPage() {
    const location = useLocation();
    const Productid = new URLSearchParams(location.search).get('product');
    const [visibility, setVisibility] = useState(false)
    return (
        <div key={Productid} className={`w-full ${visibility ? 'h-[0px]' : ''} flex flex-col lg:flex-row justify-center gap-4 bg-off-white p-8 font-[GraphikRegular]`}>
            <div className='w-0 lg:w-3/12 h-[60vh] hidden lg:flex justify-end items-end'>
                <img className='w-max h-max' src="images/zoomed-product.png" alt="" />
            </div>
            <div className='flex lg:hidden flex-col gap-2 font-[GraphikSemibold]'>
                <div className='w-full flex justify-between items-center'>
                    <div className='w-full flex flex-col text-grey font-[500] uppercase font-[Manscaped]'>
                        <span className='text-3xl lg:text-2xl xl:text-3xl'>
                            {`The`}
                        </span>
                        <span className='text-5xl lg:text-4xl xl:text-5xl leading-7'>
                            {`BEARD HEDGER`}
                        </span>
                    </div>
                    <img className='w-max h-max' src="images/returnpolicy.png" alt="returnpolicy" />
                </div>
                <div className='w-full text-lg md:text-base xl:text-lg text-grey font-[600] uppercase'>
                    {`electric Beard Trimmer`}
                </div>
            </div>
            <SliderSection />
            <DetailSection visibility={visibility} setVisibility={setVisibility} />
        </div>
    );
};

export default ProductPage;

function SliderSection() {
    const [activeSlide, setActiveSlide] = useState(0)
    const Slides = useSelector((data) => data.productImages)
    return (
        <div
            className='w-full lg:w-5/12 h-[calc(100vh-300px)] md:h-[calc(100vh-200px)] lg:h-[calc(100vh-340px)] xl:h-[calc(100vh-200px)] 
            flex flex-col justify-center items-center gap-6 lg:border-l border-gray-200'
        >
            <Swiper
                style={{
                    '--swiper-navigation-color': 'transparent',
                    '--swiper-pagination-color': 'transparent',
                }}
                onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
                spaceBetween={10}
                navigation={true}
                modules={[FreeMode, Navigation]}
                className='w-[100%] sm:w-[75%] lg:w-[95%] xl:w-[85%] 2xl:w-[75%] h-[75%] md:h-[85%] lg:h-[75%] max-h-[100vw]'
            >
                {
                    Slides && Slides?.map((data) => (
                        <SwiperSlide key={data?.id} className='!w-full !h-full'>
                            <img className='w-full h-full' src={data?.image} alt={data.id} />
                        </SwiperSlide>
                    ))
                }

            </Swiper>
            <Swiper
                spaceBetween={15}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="!w-max"
            >
                {
                    Slides && Slides?.map((data, index) => (
                        <SwiperSlide key={data?.id} className={`!w-14 !h-14 ${activeSlide === index ? 'border-2 border-sand-1' : ''}`}>
                            <img className='!w-full !h-full' src={data?.image} alt={data.id} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
};

function DetailSection({ visibility, setVisibility }) {
    const [radio, setRadio] = useState(true)
    const [radioSecond, setRadioSecond] = useState(false)
    const [index, setIndex] = useState(null)
    const Data = useSelector((data) => data.slider)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);
    return (
        <div className='w-full lg:w-4/12 font-[GraphikRegular]'>
            {
                windowWidth <= 767
                    ?
                    <VideoPlayerMobileModel visibility={visibility} event={setVisibility} index={index} />
                    :
                    <VideoPlayerDesktopModel visibility={visibility} event={setVisibility} index={index} />
            }
            <div className={`w-full xl:w-[80%] 2xl:w-[70%] flex flex-col gap-3 ${visibility ? 'hidden' : ''}`}>
                <div className='w-full hidden lg:flex  justify-between items-center'>
                    <div className='w-full flex flex-col text-grey font-[500] uppercase font-[Manscaped]'>
                        <span className='text-3xl lg:text-2xl xl:text-3xl'>
                            {`The`}
                        </span>
                        <span className='text-5xl lg:text-4xl xl:text-5xl leading-7'>
                            {`BEARD HEDGER`}
                        </span>
                    </div>
                    <img className='w-max h-max' src="images/returnpolicy.png" alt="returnpolicy" />
                </div>
                <div className='hidden lg:block w-full text-lg md:text-base xl:text-lg text-grey font-[600] uppercase'>
                    {`electric Beard Trimmer`}
                </div>

                <div className='w-full flex flex-col gap-2 text-base lg:text-sm xl:text-base text-light-grey-1'>
                    <span>
                        {`A premium beard-sculpting machine with enough power and precision to craft your style in a single swipe.`}
                    </span>
                    <ul className='list-disc !text-sand ml-4'>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`Titanium-Coated Stainless Steel T-Blade`}
                            </span>
                        </li>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`20 Different Lengths, Selected with Zoom Wheel `}
                            </span>
                        </li>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`60-Minute Runtime Li-ion Battery`}
                            </span>
                        </li>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`Three-Level LED Charge Indicator`}
                            </span>
                        </li>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`Waterproof`}
                            </span>
                        </li>
                        <li className='list-outside'>
                            <span className='!text-light-grey-1'>
                                {`Includes AC Adapter, USB-C Cable, Length-Setting Comb, and Travel Bag`}
                            </span>
                        </li>
                    </ul>
                    <div className='w-full flex justify-between gap-1 text-light-grey py-2'>
                        {
                            Data && Data?.map((data, index) => {
                                let tag = "3 Reasons You Need This"
                                if(index === 1) tag = "What People Are Saying"
                                else if(index === 2) tag = "How to Use"
                                return (
                                    <div key={index} className={`w-1/3 ${index <= 2 ? 'flex' : 'hidden'} flex-col items-center gap-2 cursor-pointer`}>
                                        <div
                                            onClick={() => {
                                                setIndex(index)
                                                setVisibility(!visibility)
                                            }}
                                            className='w-[100px] sm:w-[140px] lg:w-[80px] xl:w-[100px] 2xl:w-[120px] h-[100px] sm:h-[140px] lg:h-[80px] xl:h-[100px] 2xl:h-[120px] 
                                                flex justify-center items-center overflow-hidden rounded-full border-2 border-gray-300'
                                        >
                                            <video className='w-max h-max' src={data?.url} autoPlay loop muted playsInline></video>
                                        </div>
                                        <div className='text-sm lg:text-xs xl:text-sm text-center'>
                                            {tag}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <hr className='my-2' />
                <div className='w-full flex flex-col gap-2'>
                    <div className='w-full flex justify-between items-center'>
                        <input className='hidden' type="radio" readOnly checked={radio ? true : false} />
                        <div className='flex items-center gap-2'>
                            <div
                                onClick={() => {
                                    if (radio !== true) setRadio(true)
                                    setRadioSecond(false)
                                }}
                                className='!min-w-[24px] !h-6 flex items-center justify-center rounded-full border-2 border-gray-300 cursor-pointer'
                            >
                                <div className={`${radio ? 'block' : 'hidden'} !w-4 !h-4 rounded-full bg-sand`}></div>
                            </div>
                            <div className='max-w-full lg:max-w-[250px] text-base md:text-sm xl:text-base text-grey font-[600] uppercase'>
                                {`the BEARD HEDGER™ + peak hygiene plan`}
                            </div>
                        </div>
                        <img className='w-max h-max' src="images/savetag.png" alt="savetag" />
                    </div>
                    <div className='flex items-center gap-2 text-base xl:text-lg font-[GraphikSemibold]'>
                        <span>
                            {`$94.99`}
                        </span>
                        <span className='font-[400]'>
                            {`($99.99 Value)`}
                        </span>
                        <span className='text-sand-1'>
                            {`FREE SHIPPING`}
                        </span>
                    </div>
                    <div className='w-full text-base lg:text-sm xl:text-base text-light-grey-1'>
                        {`Your Peak Hygiene Plan includes a refill of Beard Shampoo and Beard Conditioner, billed at $20.99 + tax every 3 months. No commitment, cancel anytime.`}
                    </div>
                </div>

                <hr className='my-2' />
                <div className='w-full flex items-center gap-2'>
                    <div className='relative w-[48%] h-[55px] border-2 border-gray-300 bg-white'>
                        <select
                            style={{ appearance: 'none', WebkitAppearance: 'none', MozAppearance: 'none' }}
                            defaultValue="1"
                            className='w-full h-full bg-transparent pl-4 pt-3 cursor-pointer'
                            name="quantity">
                            <option value="1">
                                1
                            </option>
                            <option value="2">
                                2
                            </option>
                            <option value="3">
                                3
                            </option>
                            <option value="4">
                                4
                            </option>
                        </select>
                        <span className='absolute top-[10%] left-[16px] text-light-grey text-xs'>
                            QUANTITY
                        </span>
                        <Icon className='absolute top-[15%] right-[10px] text-4xl text-sand-1 cursor-pointer' icon={'ic:outline-arrow-drop-down'}/>
                    </div>
                    <button className='w-[48%] h-[50px] flex justify-center items-center bg-sand-1 rounded-full text-white font-[GraphikMedium] hover:bg-black duration-300'>
                        {`Add to Cart`}
                    </button>
                </div>

                <hr className='my-2' />
                <div className='w-full flex flex-col gap-2'>
                    <input className='hidden' type="radio" readOnly defaultChecked={radioSecond ? true : false} />
                    <div className='w-full flex items-center gap-2'>
                        <div
                            onClick={() => {
                                if (radioSecond !== true) setRadioSecond(true)
                                setRadio(false)
                            }}
                            className='!min-w-[24px] !h-6 flex items-center justify-center rounded-full border-2 border-gray-300 cursor-pointer'
                        >
                            <div className={`${radioSecond ? 'block' : 'hidden'} !w-4 !h-4 rounded-full bg-sand`}></div>
                        </div>
                        <div className='text-base md:text-sm xl:text-base text-grey font-[600] uppercase'>
                            {`the BEARD HEDGER™ + peak hygiene plan`}
                        </div>
                    </div>
                    <div className='flex items-center gap-2 text-base xl:text-lg font-[GraphikSemibold] ml-8'>
                        <span>
                            {`$94.99`}
                        </span>
                        <span className='text-sand-1'>
                            {`FREE SHIPPING`}
                        </span>
                    </div>
                </div>

                <hr className='my-2' />
                <div className='w-full flex flex-col gap-3'>
                    <div className='w-full flex justify-center items-center gap-2'>
                        <img className='w-max-h-max' src="images/shipping.png" alt="shipping" />
                        <div className='text-grey text-lg md:text-base uppercase'>
                            {`FREE SHIPPING`}
                        </div>
                    </div>
                    <div className='w-full flex justify-center items-center gap-2'>
                        <img className='w-max-h-max' src="images/guarantee.png" alt="guarantee" />
                        <div className='text-grey text-lg md:text-base uppercase'>
                            {`30 DAYS MONEY-BACK GUARANTEE`}
                        </div>
                    </div>
                </div>
                <hr className='my-2' />
            </div>
        </div>
    );
};