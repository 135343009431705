import React, { useState } from 'react'
import VideoSlider from '../components/player/VideoSlider';
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const [visibility, setVisibility] = useState(false);
    return (
        <>
            <Banner />
            <VideoSection visibility={visibility} setVisibility={setVisibility} />
        </>
    );
};

export default HomePage;

function Banner() {
    const navigate = useNavigate();
    return (
        <div className={`w-full h-[calc(100vh-125px)] md:h-[calc(100vh-119px)] flex justify-center lg:justify-start items-center bg-banner !bg-cover !bg-no-repeat !bg-center font-[GraphikMedium]`}>
            <div className='w-max flex flex-col items-center md:items-start gap-5 ml-0 md:ml-20 lg:ml-28 xl:ml-40 2xl:ml-60'>
                <div className='flex flex-col text-center md:text-start banner-text pt-5 font-[FreightBigProBlack-Regular]'>
                    <span>
                        {`The best`}
                    </span>
                    <span>
                        {`in men’s`}
                    </span>
                    <span>
                        {`grooming.`}
                    </span>
                </div>
                <div className='flex items-center gap-1 text-white'>
                    <span>
                        {`Free Shipping`}
                    </span>
                    <span className='text-sand'>
                        {`+`}
                    </span>
                    <span>
                        {`2 Free Gifts`}
                    </span>
                </div>
                <button
                    onClick={() => {
                        navigate(`/product?product=${1}`)
                        window.scrollTo(0, 0);
                    }}
                    className='w-max bg-sand-1 text-white rounded-full p-3 px-10 select-none hover:scale-105 duration-200 font-[GraphikSemibold]'
                >
                    {`Shop now`}
                </button>
            </div>
        </div>
    );
};

function VideoSection({ visibility, setVisibility }) {
    return (
        <div className={`overflow-hidden w-full ${visibility ? 'h-0' : 'h-max lg:min-h-screen flex flex-col justify-center items-center gap-12 p-10 pt-16 px-2 lg:px-10 bg-section bg-center !bg-no-repeat !bg-cover'} `}>
            <div className='w-2/3 text-center text-3xl md:text-4xl xl:text-5xl font-[900] font-[FreightBigProBlack-Regular]'>
                {'Catch some snip-its'}
            </div>
            <VideoSlider visibility={visibility} setVisibility={setVisibility} />
        </div>
    );
};

// className='w-[31%] xs:w-[32%] h-[240px] xs:h-[320px] sm:h-[400px] cursor-pointer'