import { Icon } from '@iconify-icon/react';
import React from 'react'
import { useState } from 'react';

function Header() {
    const [mobileMenu, setMobileMenu] = useState(false)
    return (
        <div className='w-full flex flex-col font-[GraphikMedium]'>
            <div className='w-full flex justify-center items-center gap-4 px-4 lg:px-0 py-3'>
                <img className='w-max h-max hidden md:block' src="images/headertag.png" alt="tag" />
                <img className='w-max h-max block md:hidden' src="images/headertag-2.png" alt="tag" />
                <div className='text-grey text-sm xl:text-base'>
                    {`FREE BOXERS + TOILETRY BAG + FREE SHIPPING WITH THE PURCHASE OF THE PERFORMANCE PACKAGE 4.0`}
                </div>
                <button className='hidden lg:block text-sm xl:text-base border-2 border-sand text-sand rounded-full p-1 px-4 ml-2'>
                    {`Get Limited Offer`}
                </button>
            </div>
            <div className='w-full flex justify-between items-center bg-dark py-4 px-3 font-[GraphikRegular]'>
                <div className='w-7/12 md:w-4/12 flex justify-start md:justify-center items-center gap-4'>
                    {
                        mobileMenu === false
                            ?
                            <Icon
                                onClick={() => setMobileMenu(!mobileMenu)}
                                className='block md:hidden cursor-pointer text-2xl text-light-grey'
                                icon={'ph:list-fill'}
                            />
                            :
                            <Icon
                                onClick={() => setMobileMenu(!mobileMenu)}
                                className='block md:hidden cursor-pointer text-2xl text-light-grey'
                                icon={'maki:cross-11'}
                            />
                    }
                    <img className='w-max h-max' src="images/logo.png" alt="logo" />
                </div>
                <div className='w-5/12 md:w-8/12 text-white text-sm xl:text-base flex justify-center items-center select-none'>
                    <div className='w-full xl:w-10/12 flex justify-end md:justify-around items-center gap-4'>
                        <div className='hidden md:flex justify-center items-center cursor-pointer'>
                            <span>
                                {`Products`}
                            </span>
                            <Icon className='text-2xl text-light-grey' icon={'ic:outline-arrow-drop-down'} />
                        </div>
                        <div className='hidden md:flex justify-center items-center gap-1 cursor-pointer'>
                            <span className='text-sand'>
                                {`NEW!`}
                            </span>
                            <span>
                                {`Beard Kit`}
                            </span>
                        </div>
                        <div className='hidden md:block cursor-pointer'>
                            {`Peak Hygiene Plan`}
                        </div>
                        <div className='flex justify-center items-center gap-2 cursor-pointer'>
                            <span className='hidden md:block'>
                                {`US`}
                            </span>
                            <img className='w-max h-max' src="images/usaflag.png" alt="flag" />
                        </div>
                        <div className='hidden md:block cursor-pointer'>
                            {`Account`}
                        </div>
                        <img className='cursor-pointer w-max h-max' src="images/bagicon.png" alt="bag" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;