import "vidstack/styles/base.css";
import { MediaOutlet, MediaPlayButton, MediaPlayer, MediaTimeSlider } from "@vidstack/react";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify-icon/react";

function VideoPlayerDesktop({ url, cover, visibility, onEnded, setPlayingSlide, slideNumber }) {
    const player = useRef(null);
    const slider = useRef(0);
    const [status, setStatus] = useState(0);

    const handleTimeUpdate = () => {
        if (player.current) slider.current = player.current.currentTime;
    };

    const handleSliderChange = (newValue) => {
        if (player.current) {
            setStatus(0);
            player.current.currentTime = newValue;
            slider.current = newValue;
        }
    };
    useEffect(() => {
        if (visibility === false) player.current.pause()
    }, [visibility])
    return (
        <MediaPlayer
            autoplay
            onCanPlay={() => {
                // setStatus(2);
                setPlayingSlide(slideNumber)
                player.current.play()
                setStatus(1);
            }}
            onLoadStart={() => {
                setStatus(0);
            }}
            onPlay={() => {
                setStatus(1);
            }}
            onPause={() => {
                setStatus(2);
            }}
            onPlaying={() => {
                if (status === 0) setStatus(1);
            }}
            onWaiting={() => {
                setStatus(0);
            }}
            onEnded={() => {
                setStatus(2);
                onEnded();
            }}
            onTimeUpdate={handleTimeUpdate}
            ref={player}
            title="Agent 327: Operation Barbershop"
            src={url}
            poster={cover}
            className="w-full h-full flex relative justify-center items-center"
            playsinline
            // aspectRatio={9 / 16}
        >
            <MediaPlayButton className="absolute w-full h-full flex items-center justify-center z-40">
                {
                    status === 0 && (
                        <Icon
                            icon="svg-spinners:270-ring"
                            className="text-3xl text-white"
                        ></Icon>
                    )
                }
                {
                    status === 2 && (
                        <Icon
                            icon="ic:baseline-play-arrow"
                            className="text-3xl text-white"
                        ></Icon>
                    )
                }
            </MediaPlayButton>
            <div className="absolute flex w-[90%] bottom-[3%] flex-col">
                <MediaTimeSlider
                    ref={slider}
                    value={0}
                    className="group flex h-1 w-full items-center self-end z-50"
                    trackClass="absolute top-1/2 left-0 z-0 h-[2px] w-full -translate-y-1/2 bg-gray-400 outline-none group-data-[focus]:ring-4 group-data-[focus]:ring-blue-400"
                    trackFillClass="live:bg-red-600 absolute top-1/2 left-0 z-20 h-[2px] w-[var(--slider-fill-percent)] -translate-y-1/2 bg-[#C98E4A] will-change-[width]"
                    trackProgressClass="absolute top-1/2 left-0 z-10 h-[2px] w-[var(--media-buffered-percent)] -translate-y-1/2 bg-[#878787] will-change-[width]"
                    thumbContainerClass="absolute top-0 left-[var(--slider-fill-percent)] z-20 h-full w-5 -translate-x-1/2 group-data-[dragging]:left-[var(--slider-pointer-percent)]"
                    thumbClass="display-none"
                    onDragValueChange={handleSliderChange}
                ></MediaTimeSlider>
            </div>
            <MediaOutlet />
        </MediaPlayer>
    )
}

export default VideoPlayerDesktop