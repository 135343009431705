import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/Home";
import Header from './components/common/Header';
import ProductPage from './pages/product';

function App() {
    return (
        <div className='max-w-screen w-full min-h-screen bg-white overflow-hidden'>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path='/product' element={<ProductPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;